import axios from 'axios'
import React from 'react'
import { useNavigate } from 'react-router-dom'

import { Auth0Provider } from '@auth0/auth0-react'

const AppContext = React.createContext<AppContextType | undefined>(undefined)
export const useApp = () => React.useContext(AppContext)!

export const AppProvider = ({ children }: AppProviderProps) => {
  const initialState: AppState = {}
  const [state] = React.useState<AppState>(initialState)
  const [configState, setConfigState] = React.useState<AppConfig>({
    loaded: false,
    backendUrl: '',
    auth: {
      audience: '',
      clientId: '',
      domain: '',
      redirectPath: '',
      scope: '',
      cacheLocation: 'localstorage',
      rotatingRefreshTokens: true,
    },
  })
  const navigate = useNavigate()

  const click = (path: string | undefined) => () => {
    path !== undefined ? navigate(path) : console.log('Click path is undefined')
  }

  // Load application config
  React.useEffect(() => {
    axios
      .get('/config/config.json')
      .then(function (response) {
        let cfg: AppConfig = response.data
        setConfigState({ ...cfg, loaded: true })
      })
      .catch(function (error) {
        console.log('Could not load config from /config/config.json', error)
      })
  }, [])

  if (!configState.loaded) {
    return <></>
  }

  return (
    <AppContext.Provider value={{ state: state, click: click, config: configState }}>
      <Auth0Provider
        domain={configState.auth.domain}
        clientId={configState.auth.clientId}
        redirectUri={window.location.origin}
        audience={configState.auth.audience}
        scope={configState.auth.scope}
        cacheLocation={configState.auth.cacheLocation}
        useRefreshTokens={configState.auth.rotatingRefreshTokens}
      >
        {children}
      </Auth0Provider>
    </AppContext.Provider>
  )
}

interface AppState {}

export interface AppConfig {
  loaded: boolean
  backendUrl: string
  auth: authConfigType
}

export type authConfigType = {
  audience: string
  clientId: string
  domain: string
  scope: string
  redirectPath: string
  rotatingRefreshTokens: boolean
  cacheLocation: 'localstorage' | 'memory'
}

type AppContextType = {
  state: AppState
  config: AppConfig
  click: (path: string | undefined) => () => void
}

type AppProviderProps = {
  children: React.ReactNode
}
